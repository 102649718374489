export default [
  {
    path: '/administration',
    name: 'apps-administration',
    component: () => import('@/views/administration/Administration.vue'),
    meta: {
      contentClass: 'administration-application',
      pageTitle: 'Administration',
      breadcrumb: [],
    },
    beforeEnter(to, _, next) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.roles.includes('ROLE_ADMIN')) next()
      else next({ name: 'acces-non-authorise' })
    },
  },
  {
    path: '/administration/parametres',
    name: 'apps-administration-parametres',
    component: () => import('@/views/administration/parametres/Parametres.vue'),
    meta: {
      contentClass: 'administration-application',
      pageTitle: 'Paramètres',
      breadcrumb: [
        {
          text: 'Administration',
          to: '/administration/',
        },
      ],
    },
    beforeEnter(to, _, next) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.roles.includes('ROLE_ADMIN')) next()
      else next({ name: 'acces-non-authorise' })
    },
  },
  {
    path: '/administration/mises-a-jour',
    name: 'apps-administration-maj',
    component: () => import('@/views/administration/ChangeLog.vue'),
    meta: {
      contentClass: 'administration-application-maj',
      pageTitle: 'Notes de mise à jour',
      breadcrumb: [
        {
          text: 'Administration',
          to: '/administration/',
        },
      ],
    },
    beforeEnter(to, _, next) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.roles.includes('ROLE_ADMIN')) next()
      else next({ name: 'acces-non-authorise' })
    },
  },
]

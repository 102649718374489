export default [
  {
    path: '/liste',
    name: 'apps-tickets',
    component: () => import('@/views/tickets/Liste.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/tickets/:filter',
    name: 'apps-tickets-filter',
    component: () => import('@/views/tickets/Liste.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-tickets',
    },
    beforeEnter(to, _, next) {
      if (['a-traiter', 'en-cours', 'clotures'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/tickets/statut/:statut',
    name: 'apps-tickets-statut',
    component: () => import('@/views/tickets/Liste.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-tickets',
    },
    /*beforeEnter(to, _, next) {
      if (['0', '1', '2', '3', '4'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },*/
  },
  {
    path: '/tickets/priorite/:priorite',
    name: 'apps-tickets-priorite',
    component: () => import('@/views/tickets/Liste.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-tickets',
    },
    /*beforeEnter(to, _, next) {
      if (['0', '1', '2', '3', '4'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },*/
  },
]

export default [
  {
    path: '/administration/statuts',
    name: 'apps-statuts-list',
    component: () => import('@/views/statuts/statuts-list/StatutsList.vue'),
    meta: {
      pageTitle: 'Liste des Statuts',
      breadcrumb: [
        {
          text: 'Administration',
          to: '/administration/',
        },
        {
          text: 'Statuts',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.roles.includes('ROLE_ADMIN')) next()
      else next({ name: 'acces-non-authorise' })
    },
  },
  /*{
    path: '/administration/statuts/:id',
    name: 'apps-statuts-view',
    component: () => import('@/views/statuts/statuts-view/StatutsView.vue'),
    beforeEnter(to, _, next) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.roles.includes('ROLE_ADMIN')) next()
      else next({ name: 'acces-non-authorise' })
    },
  },*/
  {
    path: '/administration/statuts/modifier/:id',
    name: 'apps-statuts-edit',
    component: () => import('@/views/statuts/statuts-edit/StatutsEdit.vue'),
    meta: {
      pageTitle: 'Modifier le statut',
      breadcrumb: [
        {
          text: 'Administration',
          to: '/administration/',
        },
        {
          text: 'Utilisateurs',
          to: '/administration/statuts',
        },
        {
          text: 'Modifier',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.roles.includes('ROLE_ADMIN')) next()
      else next({ name: 'acces-non-authorise' })
    },
  },
]

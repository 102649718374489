import Vue from 'vue'

// axios
import axios from 'axios'
import axiosIns from "axios";

const axiosInsRefresh = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
axiosInsRefresh.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
Vue.prototype.$http = axiosInsRefresh

export default axiosInsRefresh

import mock from '@/@fake-db/mock'

/* eslint-disable global-require */
const data = {
  emails: [
    {
      id: 1,
      from: {
        email: 'tommys@mail.com',
        name: 'Tommy Sicilia',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com',
        },
      ],
      subject: 'Theme Update',
      cc: [],
      bcc: [],
      message:
        '<p>Hi John,</p><p>Biscuit lemon drops marshmallow. Cotton candy marshmallow bear claw. Dragée tiramisu cookie cotton candy. Carrot cake sweet roll I love macaroon wafer jelly soufflé I love dragée. Jujubes jelly I love carrot cake topping I love. Sweet candy I love chupa chups dragée. Tart I love gummies. Chocolate bar carrot cake candy wafer candy canes oat cake I love. Sesame snaps icing pudding sweet roll marshmallow. Cupcake brownie sweet roll chocolate bar I love gummies. Biscuit biscuit macaroon sesame snaps macaroon icing I love soufflé caramels. Apple pie candy jelly. I love icing gummi bears jelly-o pie muffin apple pie.</p><p>Marshmallow halvah brownie cake marzipan ice cream marshmallow. I love lollipop toffee croissant liquorice wafer muffin. Lollipop jelly beans caramels lollipop tootsie roll pudding pie macaroon tootsie roll. Oat cake jujubes gummies cake cake powder cupcake soufflé muffin. Chocolate caramels muffin tart. Jelly beans caramels dessert cotton candy liquorice chocolate cake. Chupa chups muffin bear claw I love. Biscuit jujubes soufflé tart caramels pie sugar plum. Croissant jelly beans cake. Ice cream chocolate liquorice dessert cookie chocolate cake. Powder tart sweet roll macaroon croissant. Sweet tootsie roll macaroon gummi bears macaroon. Gingerbread cake tart.</p><p>Regrads,</p><p>Kristeen Sicilia</p>',
      attachments: [
        {
          fileName: 'log.txt',
          thumbnail: require('@/assets/images/icons/txt.png'),
          url: '',
          size: '5mb',
        },
        {
          fileName: 'performance.xls',
          thumbnail: require('@/assets/images/icons/xls.png'),
          url: '',
          size: '10mb',
        },
      ],
      isStarred: false,
      labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 2,
      from: {
        email: 'tressag@mail.com',
        name: 'Tressa Gass',
        avatar: require('@/assets/images/avatars/2.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com',
        },
      ],
      subject: 'Company Report',
      cc: ['vrushankbrahmshatriya@mail.com'],
      bcc: ['menka@mail.com'],
      message:
        ' <p>Hello John,</p><p>Chocolate bar chupa chups sweet roll chocolate muffin macaroon liquorice tart. Carrot cake topping jelly-o cupcake sweet apple pie jelly I love. Chocolate cake I love dessert carrot cake tootsie roll chocolate I love. Tootsie roll pie marzipan sesame snaps. Lemon drops cake I love chocolate I love. Cheesecake croissant croissant cheesecake tootsie roll chocolate cake caramels. I love I love cake jelly. Pastry jelly beans I love I love. I love tootsie roll gummies toffee I love cookie cookie. Brownie tart croissant.</p><p>I love jelly pie. Croissant jelly beans lemon drops oat cake. Cupcake carrot cake ice cream cake cookie jelly-o fruitcake jelly macaroon. Biscuit muffin cupcake chocolate bar lollipop danish.</p><p>Ice cream I love I love lemon drops cotton candy macaroon. Jujubes soufflé oat cake sweet roll bear claw cotton candy cookie toffee tootsie roll. Danish sesame snaps sesame snaps cookie jelly danish chocolate bar.</p><p>Fruitcake pastry bear claw chocolate cookie. Biscuit icing bear claw. Sweet roll liquorice jelly-o muffin toffee I love wafer. Candy pastry halvah. Pie pastry candy canes tart. Fruitcake I love liquorice icing sweet roll macaroon. I love ice cream jelly-o biscuit brownie marzipan topping pudding sesame snaps. Chupa chups brownie brownie cotton candy sugar plum toffee. Tiramisu tootsie roll gingerbread biscuit I love ice cream brownie sesame snaps. I love croissant I love I love brownie pie marshmallow.</p><p>Regrads</p><p>Tressa Gass</p>',
      attachments: [],
      isStarred: true,
      labels: ['company', 'private'],
      time: 'Mon Dec 10 2018 07:55:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 3,
      from: {
        email: 'hettiem@mail.com',
        name: 'Hettie Mcerlean',
        avatar: require('@/assets/images/avatars/3.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com',
        },
        {
          name: 'Pixinvent',
          email: 'hello@pixinvent.net',
        },
      ],
      subject: 'Order Delivered',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John,</p><p>Chocolate bar chupa chups sweet roll chocolate muffin macaroon liquorice tart. Carrot cake topping jelly-o cupcake sweet apple pie jelly I love. Chocolate cake I love dessert carrot cake tootsie roll chocolate I love. Tootsie roll pie marzipan sesame snaps. Lemon drops cake I love chocolate I love. Cheesecake croissant croissant cheesecake tootsie roll chocolate cake caramels. I love I love cake jelly. Pastry jelly beans I love I love. I love tootsie roll gummies toffee I love cookie cookie. Brownie tart croissant.</p><p>I love jelly pie. Croissant jelly beans lemon drops oat cake. Cupcake carrot cake ice cream cake cookie jelly-o fruitcake jelly macaroon. Biscuit muffin cupcake chocolate bar lollipop danish.</p><p>Ice cream I love I love lemon drops cotton candy macaroon. Jujubes soufflé oat cake sweet roll bear claw cotton candy cookie toffee tootsie roll. Danish sesame snaps sesame snaps cookie jelly danish chocolate bar.</p><p>Fruitcake pastry bear claw chocolate cookie. Biscuit icing bear claw. Sweet roll liquorice jelly-o muffin toffee I love wafer. Candy pastry halvah. Pie pastry candy canes tart. Fruitcake I love liquorice icing sweet roll macaroon. I love ice cream jelly-o biscuit brownie marzipan topping pudding sesame snaps. Chupa chups brownie brownie cotton candy sugar plum toffee. Tiramisu tootsie roll gingerbread biscuit I love ice cream brownie sesame snaps. I love croissant I love I love brownie pie marshmallow.</p><p>Regrads</p><p>Hettie Mcerlean</p>',
      attachments: [],
      isStarred: false,
      labels: ['company'],
      time: 'Mon Dec 10 2018 08:35:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 4,
      from: {
        email: 'louettae@mail.com',
        name: 'Louetta Esses',
        avatar: require('@/assets/images/avatars/4.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com',
        },
      ],
      subject: 'Waterfall Model Update',
      cc: [],
      bcc: [],
      message:
        '<p>Hi John,</p><p>Biscuit lemon drops marshmallow. Cotton candy marshmallow bear claw. Dragée tiramisu cookie cotton candy. Carrot cake sweet roll I love macaroon wafer jelly soufflé I love dragée. Jujubes jelly I love carrot cake topping I love. Sweet candy I love chupa chups dragée. Tart I love gummies. Chocolate bar carrot cake candy wafer candy canes oat cake I love. Sesame snaps icing pudding sweet roll marshmallow. Cupcake brownie sweet roll chocolate bar I love gummies. Biscuit biscuit macaroon sesame snaps macaroon icing I love soufflé caramels. Apple pie candy jelly. I love icing gummi bears jelly-o pie muffin apple pie.</p><p>Marshmallow halvah brownie cake marzipan ice cream marshmallow. I love lollipop toffee croissant liquorice wafer muffin. Lollipop jelly beans caramels lollipop tootsie roll pudding pie macaroon tootsie roll. Oat cake jujubes gummies cake cake powder cupcake soufflé muffin. Chocolate caramels muffin tart. Jelly beans caramels dessert cotton candy liquorice chocolate cake. Chupa chups muffin bear claw I love. Biscuit jujubes soufflé tart caramels pie sugar plum. Croissant jelly beans cake. Ice cream chocolate liquorice dessert cookie chocolate cake. Powder tart sweet roll macaroon croissant. Sweet tootsie roll macaroon gummi bears macaroon. Gingerbread cake tart.</p><p>Regrads,</p><p>Kristeen Sicilia</p>',
      attachments: [
        {
          fileName: 'report.doc',
          thumbnail: require('@/assets/images/icons/doc.png'),
          url: '',
          size: '32mb',
        },
      ],
      isStarred: false,
      labels: ['important'],
      time: 'Mon Dec 11 2018 09:04:10 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 5,
      from: {
        email: 'bposvner0@zdnet.com',
        name: 'Bobbie Posvner',
        avatar: require('@/assets/images/avatars/11.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@dot.gov',
        },
      ],
      subject: 'Re-contextualized zero administration toolset',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John,</p><p>Epiboly henbill hemichorea composedness resequent hearthpenny racialization freeboard Reboulia Albuginaceae Violales Trisagion resinlike plumlet insalubrious Urocoptis Orthoceratidae ogmic plateaux reinsist preconsumption rattener venipuncture Rhadamanthine</p><p>perversely corpusculated theophoric uncemented weiring myolipoma gudesire dramatics trichomaphyte adventitious video polychaete glossorrhaphy bestially zoospore nonnotional sawbones unprejudiciable wali collaborative coppled shammish manless predisorder</p>',
      attachments: [],
      isStarred: true,
      labels: ['private'],
      time: 'Tue Dec 12 2018 11:55:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'spam',
      isRead: true,
    },
    {
      id: 6,
      from: {
        email: 'rgilder1@illinois.edu',
        name: 'Rebecca Gilder',
        avatar: require('@/assets/images/avatars/6.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@google.co.uk',
        },
      ],
      subject: 'Integrated bi-directional help-desk',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John, </p><p>irretraceably indestructibility sisi fiddle pyretic Gerbera vocable hoped Chartreux thinglet whister fetiferous Guhayna overloud yuca suggestibly atmiatrics serioludicrous prehaustorium androgenic betulin chiropterite sprittail atrochal</p><p>stopper acinaceous bandcutter unprostituted undependableness Saffarid parasympathetic dishonorer embryogeny overscrupulous deicidal Tapuya roupily devotee alfet sophiologic hydrobromide onlook preverb Dottore primitial frostlike overbig Monocyclica</p>',
      attachments: [],
      isStarred: false,
      labels: ['personal'],
      time: 'Thu Dec 13 2018 08:25:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 7,
      from: {
        email: 'swilby2@yandex.ru',
        name: 'Shawn Wilby',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@altervista.org',
        },
      ],
      subject: 'Devolved regional product',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John, </p><p>scalpellum unisometrical emend esotrope gether separatical passionwise cringe flawful annotinous jiboa thrombotic essoinment successional backrope oligohemia gitaligenin formulaic bradycinesia paralgesic eumycetic receptoral eyrie yearly</p><p>atomistical semischolastic clour discardment pseudelytron cheson path Bennet Herat pinchem Tho extravagantly shaking procidence flocker amidoketone gyric Moi petrolific clink immunization estrepement spalder poitrail</p>',
      attachments: [],
      isStarred: false,
      labels: ['company'],
      time: 'Fri Dec 14 2018 04:49:23 GMT+0000 (GMT)',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
    {
      id: 8,
      from: {
        email: 'wmannering3@mozilla.org',
        name: 'Waldemar Mannering',
        avatar: require('@/assets/images/avatars/11.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@sciencedaily.com',
        },
      ],
      subject: 'Quality-focused methodical flexibility',
      cc: [],
      bcc: [],
      message:
        '<p>Hi John, </p><p>wartproof ketoheptose incomplicate hyomental organal supermaterial monogene sophister nizamate rightle multifilament phloroglucic overvehement boatloading derelictly probudgeting archantiquary unknighted pallograph Volcanalia Jacobitiana ethyl neth Jugatae</p><p>noumenalize irredential energeia phlebotomist galp dactylitis unparticipated solepiece demure metarhyolite toboggan unpleased perilaryngeal binoxalate rabbitry atomic duali dihexahedron Pseudogryphus boomboat obelisk undreaded unadmired podometer</p>',
      attachments: [],
      isStarred: false,
      labels: ['private'],
      time: 'Tue Dec 15 2018 11:02:28 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: false,
    },
    {
      id: 9,
      from: {
        email: 'hfrostdyke4@scientificamerican.com',
        name: 'Heath Frostdyke',
        avatar: require('@/assets/images/avatars/9.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@weibo.com',
        },
      ],
      subject: 'Secured optimal algorithm',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John, </p><p>prefertility volumetrical subdivision Alpinist hypostomous foreyard lede knuckling Agade viking protometallic unsting palaeobiologist trierarchic bigheartedness Slartibartfast tabitude popadam idioplasm fabulous arsenite cingle backhandedness denizenship</p><p>argo trickish dependingly unhouseled aegeriid ophthalmophlebotomy crazy freedwoman unspinsterlike clinical epistemologically hermodactyl humbugger misogynist plunder Sabbathly tabule feckless cerite porringer benumb duograph propatagian pressman</p>',
      attachments: [],
      isStarred: true,
      labels: ['personal'],
      time: 'Tue Jan 01 2018 18:31:19 GMT+0000 (GMT)',
      replies: [],
      folder: 'trash',
      isRead: false,
    },
    {
      id: 10,
      from: {
        email: 'pjentzsch5@tamu.edu',
        name: 'Paulita Jentzsch',
        avatar: require('@/assets/images/avatars/7.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@skype.com',
        },
      ],
      subject: 'Profound user-facing frame',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John, </p><p>unstintedly septifarious prevalid implicative esere Kedarite Nekkar encampment Issedones Saururus Turkize Tshi misengrave lamnid benzamine rigidulous octonion hydrophilous coxalgic nasturtium epileptogenic biporose undenominationalism widu</p><p>melioration pager infiltrate plouky tribesmanship transudate heinous exhibitionistic klendusic lienocele tractate unemotioned castigate selenate appealable Palladianism chloracetate penna moellon detector halcyon amidofluorid damascene hollaite</p>',
      attachments: [
        {
          fileName: 'UtNulla.xls',
          thumbnail: require('@/assets/images/icons/xls.png'),
          url: '',
          size: '1mb',
        },
      ],
      isStarred: true,
      labels: ['important'],
      time: 'Tue Jan 03 2018 08:05:33 GMT+0000 (GMT)',
      replies: [],
      folder: 'draft',
      isRead: false,
    },
    {
      id: 11,
      from: {
        email: 'lminghetti6@yale.edu',
        name: 'Lowell Minghetti',
        avatar: require('@/assets/images/avatars/4.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@fda.gov',
        },
      ],
      subject: 'Reactive full-range encryption',
      cc: [],
      bcc: [],
      message:
        '<p>Hi John, </p><p>Jaime stadimeter reshearer adfix exaristate herby chrysomonad ostentous Abrahamic Marsipobranchii skil linguodental relaster restbalk enhorror glycerizine foremelt difficultness Ostreidae Euphues panduriform howler Finmark primordality</p><p>semicurvilinear skeet sociogenetic dicephalism ghalva ethnicon katagenesis tasklike triachenium abuser henbane strubbly streetway Ramaism besetting saccos enzymically wickiup inopulent poetship Miranda hemianesthesia sphericle oariopathy</p>',
      attachments: [
        {
          fileName: 'ElementumLigula.js',
          thumbnail: require('@/assets/images/icons/js.png'),
          url: '',
          size: '29mb',
        },
      ],
      isStarred: false,
      labels: ['company'],
      time: 'Tue Jan 03 2018 01:05:20 GMT+0000 (GMT)',
      replies: [],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 12,
      from: {
        email: 'efinessy7@sbwire.com',
        name: 'Eugenie Finessy',
        avatar: require('@/assets/images/avatars/2.png'),
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@odnoklassniki.ru',
        },
      ],
      subject: 'Polarised holistic protocol',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John, </p><p>nonmotile generale punctated humanify escharine unlatch alrighty unshepherding standstill upcrane unilobular prefrontal primate fluviatic premundane tritocone compaternity dolina phonautographic cobby corse neurohypnotic cyrtopia wreathy</p><p>slanderousness Lemosi myxochondroma haemogram electrothermostat piperonyl spital reappeal pyopneumothorax Yunca eyoty forecourse extensive kickout germanization vigilant Criophoros muss aortostenosis Berchta phonophotoscopic precorrespondence rigidist castling</p>',
      attachments: [],
      isStarred: false,
      labels: ['personal'],
      time: 'Tue Jan 04 2018 21:26:54 GMT+0000 (GMT)',
      replies: [],
      folder: 'sent',
      isRead: true,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Emails
// ------------------------------------------------
mock.onGet('/apps/email/emails').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', folder = 'inbox', label } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()

  function isInFolder(email) {
    if (folder === 'trash') return email.folder === 'trash'
    if (folder === 'starred') return email.isStarred && email.folder !== 'trash'
    return email.folder === (folder || email.folder) && email.folder !== 'trash'

    // email.folder === (folder || email.folder)

    // if (filter === 'important') return task.isImportant && !task.isDeleted
    // if (filter === 'completed') return task.isCompleted && !task.isDeleted
    // if (filter === 'deleted') return task.isDeleted
    // return !task.isDeleted
  }

  const filteredData = data.emails.filter(
    email =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      email.subject.toLowerCase().includes(queryLowered) &&
      isInFolder(email) &&
      (label ? email.labels.includes(label) : true),
  )
  /* eslint-enable  */

  // ------------------------------------------------
  // Email Meta
  // ------------------------------------------------
  const emailsMeta = {
    inbox: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'inbox').length,
    draft: data.emails.filter(email => email.folder === 'draft').length,
    spam: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'spam').length,
  }

  return [
    200,
    {
      emails: filteredData.reverse(),
      emailsMeta,
    },
  ]
})

// ------------------------------------------------
// POST: Update Email
// ------------------------------------------------
mock.onPost('/apps/email/update-emails').reply(config => {
  const { emailIds, dataToUpdate } = JSON.parse(config.data)

  function updateMailData(email) {
    Object.assign(email, dataToUpdate)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailData(email)
  })

  return [200]
})

// ------------------------------------------------
// POST: Update Emails Label
// ------------------------------------------------
mock.onPost('/apps/email/update-emails-label').reply(config => {
  const { emailIds, label } = JSON.parse(config.data)

  function updateMailLabels(email) {
    const labelIndex = email.labels.indexOf(label)

    if (labelIndex === -1) email.labels.push(label)
    else email.labels.splice(labelIndex, 1)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailLabels(email)
  })

  return [200]
})

// ------------------------------------------------
// GET: Paginate Existing Email
// ------------------------------------------------
mock.onGet('/apps/email/paginate-email').reply(config => {
  const { dir, emailId } = config.params

  const currentEmailIndex = data.emails.findIndex(e => e.id === emailId)

  const newEmailIndex = dir === 'previous' ? currentEmailIndex - 1 : currentEmailIndex + 1

  const newEmail = data.emails[newEmailIndex]

  return newEmail ? [200, newEmail] : [404]
})

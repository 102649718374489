import Vue from 'vue'
import {
  BootstrapVue, BootstrapVueIcons, ModalPlugin, ToastPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { MonthPicker, MonthPickerInput } from 'vue-month-picker'
import flatPickr from 'vue-flatpickr-component'
import VueCountdownTimer from 'vuejs-countdown-timer'
import flatpickr from 'flatpickr'
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBed, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { French } from 'flatpickr/dist/l10n/fr'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/axiosRefresh'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
library.add(faBed, faScrewdriverWrench)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.use(flatPickr)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('font-awesome-icon', FontAwesomeIcon)
flatpickr.setDefaults({
  locale: French,
  enableTime: false,
  dateFormat: 'd-m-Y',
  time_24hr: true,
  minDate: 'today',
})
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueCountdownTimer)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('str_limit', (value, size) => {
  if (!value) return ''
  // eslint-disable-next-line no-param-reassign
  value = value.toString()

  if (value.length <= size) {
    return value
  }
  return `${value.substr(0, size)}...`
})

Vue.filter('stripHTML', value => {
  const div = document.createElement('div')
  div.innerHTML = value
  return div.textContent || div.innerText || ''
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
